<template>
    <div class="avatar">
        <vue-avatar
            class="avatar__photo"
            :username="username"
            :size="size"
            :customStyle="styles"
            :backgroundColor="backgroundColor"
            :src="src"
        />
        <div v-show="user.online" class="avatar__online-status" />
    </div>
</template>

<script>
    import md5 from 'js-md5';
    import Avatar from 'vue-avatar';

    export default {
        name: 'UserAvatar',
        props: {
            size: {
                type: Number,
                default: 40,
            },
            borderRadius: {
                type: Number,
                default: 5,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            const baseUrl = process.env.VUE_APP_API_URL;

            return {
                baseUrl,
            };
        },
        components: {
            'vue-avatar': Avatar,
        },
        computed: {
            username() {
                if (!this.user || !this.user.first_name) {
                    return '';
                }

                return `${this.user.first_name} ${this.user.last_name}`;
            },
            email() {
                if (!this.user || !this.user.email) {
                    return '';
                }

                return this.user.email;
            },
            src() {
                if (this.user.avatar_url != undefined) {
                    return `${process.env.VUE_APP_API_URL}/${this.user.avatar_url}`;
                }
                if (this.user.email) {
                    const emailMD5 = md5(this.email);

                    return ``;
                }

                return null;
            },
            backgroundColor() {
                return !this.username ? 'FF8000' : null;
            },
            styles() {
                return {
                    borderRadius: `${this.borderRadius}px`,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .avatar {
        position: relative;

        &__online-status {
            height: 7px;
            width: 7px;
            position: absolute;
            background: #6eceb2;
            border-radius: 100%;
            border: 1px solid white;
            right: 0;
            bottom: 0px;
        }
    }
</style>
