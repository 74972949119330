<template>
    <div id="app">
        <component :is="config.beforeLayout" />
        <component :is="layout">
            <router-view :key="$route.path" />
        </component>
    </div>
</template>

<script>
    import * as Sentry from '@sentry/vue';
    import moment from 'moment';
    import { getLangCookie, setLangCookie } from '@/i18n';
    import ApiService from './services/api';

    export const config = { beforeLayout: null };

    export default {
        name: 'App',
        async created() {
            if (!(await this.$store.dispatch('httpRequest/getStatus'))) {
                if (this.$route.name !== 'api.error') {
                    await this.$router.replace({ name: 'api.error' });
                    return;
                }
                return;
            } else {
                if (this.$route.name === 'api.error') {
                    await this.$router.replace('/');
                    return;
                }
            }

            const apiService = new ApiService(this.$store);
            if (apiService.token()) {
                try {
                    this.$loading.show();
                    await apiService.checkApiAuth();
                    await apiService.getCompanySettings();
                } catch (e) {
                    console.log(e);
                } finally {
                    this.$loading.hide();
                }
            }
        },
        mounted() {
            if (sessionStorage.getItem('logout')) {
                this.$store.dispatch('user/setLoggedInStatus', null);
                sessionStorage.removeItem('logout');
            }
        },
        methods: {
            setUserLocale() {
                const user = this.$store.getters['user/user'];
                const cookieLang = getLangCookie();
                // Set user locale after auth
                if (user.user_language) {
                    this.$i18n.locale = user.user_language;
                    setLangCookie(user.user_language);
                    moment.locale(user.user_language);
                } else if (cookieLang) {
                    this.$i18n.locale = cookieLang;
                    moment.locale(cookieLang);
                }
            },
        },
        computed: {
            isLoggedIn() {
                // Somehow this is the only place in vue lifecycle which is working as it should be
                // All the other places brake locale in different places
                this.setUserLocale();
                return this.$store.getters['user/loggedIn'];
            },
            layout() {
                return this.$route.meta.layout || 'default-layout';
            },
            config() {
                return config;
            },
        },
        watch: {
            isLoggedIn(status) {
                if (status) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$router.push({ name: 'auth.login' });
                }
            },
        },
    };
</script>

<style lang="scss">
    @import 'sass/app';

    .at-loading-bar {
        &__inner {
            transition: width 0.5s linear;
        }
    }
</style>
