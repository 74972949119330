<template>
    <div class="picker">
        <datePicker
            v-model="model"
            :format="'yyyy-MM-dd'"
            :typeable="true"
            :clearable="true"
            :editable="true"
            :year-selector="true"
            input-class="at-input__original"
        ></datePicker>
    </div>
</template>

<script>
    import DatePicker from 'vuejs-datepicker';

    export default {
        name: 'DatePickerComponent',
        components: {
            DatePicker,
        },
        props: {
            initialDate: {
                type: [String, Date],
                default: '',
            },
        },
        data() {
            return {
                model: this.initialDate,
            };
        },
        watch: {
            model(newDate) {
                this.$emit('input', newDate);
            },
        },
    };
</script>

<style scoped lang="scss">
    .picker {
        position: relative;
        .theme-dark & {
            ::v-deep {
                .rtl {
                    direction: rtl;
                }
                .vdp-datepicker {
                    position: relative;
                    text-align: left;
                }
                .vdp-datepicker * {
                    box-sizing: border-box;
                }
                .vdp-datepicker__calendar {
                    position: absolute;
                    z-index: 100;
                    background: #333;
                    color: #c4c4cf;
                    width: 300px;
                    border: 1px solid #555;
                }
                .vdp-datepicker__calendar header {
                    display: block;
                    line-height: 40px;
                }
                .vdp-datepicker__calendar header span {
                    display: inline-block;
                    text-align: center;
                    width: 71.42857142857143%;
                    float: left;
                }
                .vdp-datepicker__calendar header .prev,
                .vdp-datepicker__calendar header .next {
                    width: 14.285714285714286%;
                    float: left;
                    text-indent: -10000px;
                    position: relative;
                }
                .vdp-datepicker__calendar header .prev:after,
                .vdp-datepicker__calendar header .next:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    border: 6px solid transparent;
                }
                .vdp-datepicker__calendar header .prev:after {
                    border-right: 10px solid #000;
                    margin-left: -5px;
                }
                .vdp-datepicker__calendar header .prev.disabled:after {
                    border-right: 10px solid #555;
                }
                .vdp-datepicker__calendar header .next:after {
                    border-left: 10px solid #000;
                    margin-left: 5px;
                }
                .vdp-datepicker__calendar header .next.disabled:after {
                    border-left: 10px solid #ddd;
                }
                .vdp-datepicker__calendar header .prev:not(.disabled),
                .vdp-datepicker__calendar header .next:not(.disabled),
                .vdp-datepicker__calendar header .up:not(.disabled) {
                    cursor: pointer;
                }
                .vdp-datepicker__calendar header .prev:not(.disabled):hover,
                .vdp-datepicker__calendar header .next:not(.disabled):hover,
                .vdp-datepicker__calendar header .up:not(.disabled):hover {
                    background: #555;
                    color: #ffa500;
                }
                .vdp-datepicker__calendar .disabled {
                    color: #ddd;
                    cursor: default;
                }
                .vdp-datepicker__calendar .flex-rtl {
                    display: flex;
                    width: inherit;
                    flex-wrap: wrap;
                }
                .vdp-datepicker__calendar .cell {
                    display: inline-block;
                    padding: 0 5px;
                    width: 14.285714285714286%;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    vertical-align: middle;
                    border: 1px solid transparent;
                }
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month,
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year {
                    cursor: pointer;
                }
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
                .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
                    border: 1px solid #555;
                    background: #555;
                    color: #ffa500;
                }
                .vdp-datepicker__calendar .cell.selected {
                    background: #555;
                    color: #ffa500;
                }
                .vdp-datepicker__calendar .cell.selected:hover {
                    background: #111;
                }
                .vdp-datepicker__calendar .cell.selected.highlighted {
                    background: #555;
                }
                .vdp-datepicker__calendar .cell.highlighted {
                    background: #cae5ed;
                }
                .vdp-datepicker__calendar .cell.highlighted.disabled {
                    color: #a3a3a3;
                }
                .vdp-datepicker__calendar .cell.grey {
                    color: #888;
                }
                .vdp-datepicker__calendar .cell.grey:hover {
                    background: inherit;
                }
                .vdp-datepicker__calendar .cell.day-header {
                    font-size: 75%;
                    white-space: nowrap;
                    cursor: inherit;
                }
                .vdp-datepicker__calendar .cell.day-header:hover {
                    background: inherit;
                }
                .vdp-datepicker__calendar .month,
                .vdp-datepicker__calendar .year {
                    width: 33.333%;
                }
                .vdp-datepicker__clear-button,
                .vdp-datepicker__calendar-button {
                    cursor: pointer;
                    font-style: normal;
                }
                .vdp-datepicker__clear-button.disabled,
                .vdp-datepicker__calendar-button.disabled {
                    color: #999;
                    cursor: default;
                }
            }
        }
    }

    ::v-deep .vdp-datepicker__calendar {
        left: auto !important;
        right: 0;
        z-index: 1000;
    }
</style>
